<template>
  <div class="scroll-progress">
    <nav class="scroll-progress__nav">
      <a :href="'#'.concat(tag.tag)" class="scroll-progress__link" v-for="tag in tags" :key="tag.key"
        :id="tag.tag.concat(tag.key)" :style="{ 'font-weight': tag.current ? '700' : '500' }">{{ tag.name }}</a>
    </nav>
    <!-- :id="tag.tag.concat(tag.key)" @click.prevent="jump(tag.tag)">{{ tag.name }}</a> -->

    <!-- <progress class="scroll-progress__bar" max="100" :value="scrollProgress">
      test
    </progress> -->

    <div class="scroll-progress__container">
      <div class="scroll-progress__progress" :style="{ '--progress-value': scrollProgress }">

      </div>
    </div>
    <!-- <div>
      {{ scrollProgress }} {{ pureProgress }}
    </div> -->
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { onMounted, watch } from 'vue';
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);


export default {
  name: "progressMenu",
  setup(props, ctx) {

    let tags = [{ key: 1, name: 'Bio', tag: 'bio', scrollerStart: 'top+=20 top', scrollerEnd: 'bottom center', startTrigger: "#bio", endTrigger: '.bio-section .buttons-div', current: true },
    { key: 2, name: 'Featured Projects', tag: 'featured', scrollerStart: 'bottom+=30 top', scrollerEnd: 'bottom center', startTrigger: "#bio", endTrigger: '.section--clients', current: false },
    { key: 3, name: 'Personal Projects', tag: 'work', scrollerStart: 'top+=80 center', scrollerEnd: 'bottom center', startTrigger: "#work", endTrigger: '.project-section', current: false },
    { key: 4, name: 'Get In touch', tag: 'contact', scrollerStart: 'top+=35 bottom-=50', scrollerEnd: 'bottom bottom', startTrigger: "#contact", endTrigger: 'body', current: false },
    ]
    const scrollProgress = ref(0);
    const pureProgress = ref(0);


    let setValues = () => {
      let nav = document.getElementsByClassName("scroll-progress__container")[0];
      let navWidth = nav.getBoundingClientRect().width;
      let tagHalfWidth = document.getElementById(tags[0].tag.concat(1)).getBoundingClientRect().width / 2;
      let tagHalfWidthAsPercent = Math.floor(tagHalfWidth / navWidth * 100);
      let boundaries = { 3: 85, 2: 63, 1: 38, 0: 0 }

      watch(scrollProgress, (newScrollValue) => {
        let keys = Object.keys(boundaries);

        tags.forEach((tag) => {
          tag.current = false;
        })
        let findBoundary = false; // Change "findBoudnary" to "findBoundary"


        keys.forEach((key) => {
          // console.log(key)

          // if (!findBoundary) {
          //   console.log('false - ', key, tags[key].current)

          if (newScrollValue >= boundaries[key]) {
            tags[key].current = true;
            findBoundary = true;
          }
          // }
        });


      })

      setTimeout(() => {

        tags.forEach((tag) => {
          let thisTagIndex = tags.indexOf(tag)
          // if (thisTagIndex < tags.length - 1) {
          let thisTag = tag;
          let nextTag = thisTagIndex == tags.length - 1 ? thisTag : tags[thisTagIndex + 1];

          tag.position = document.getElementById(tag.tag.concat(tag.key)).getBoundingClientRect().left;
          // console.log(thisTag, nextTag);
          let startTag = thisTag;
          ScrollTrigger.create({
            start: startTag.scrollerStart,
            end: startTag.scrollerEnd,
            trigger: startTag.startTrigger,
            endTrigger: startTag.endTrigger,
            // animation: tagTl,
            // snap: 0.1,
            scrub: true,
            toggleActions: "play pause resume reset",
            // markers: thisTagIndex == 1 || thisTagIndex == 2 ? true : false,
            onUpdate: (self) => {
              let currentProgress = Math.floor(self.progress * 100);
              pureProgress.value = currentProgress

              let dummyValue = Math.floor(((currentProgress) / (3 * 1.3)) + (tagHalfWidthAsPercent)) + (thisTagIndex * 33);
              if (thisTagIndex == 0) {


                scrollProgress.value = dummyValue > 38 ? 38 : dummyValue

              } else if (thisTagIndex == 1) {
                dummyValue = Math.floor(((currentProgress) / (3 * 1.3)) + (tagHalfWidthAsPercent)) + (thisTagIndex * 25);

                dummyValue = dummyValue > 63 ? 63 : dummyValue
                scrollProgress.value = dummyValue < 48 ? 38 : dummyValue



              } else if (thisTagIndex == 2) {
                dummyValue = Math.floor(((currentProgress) / (3 * 1.6)) + (tagHalfWidthAsPercent)) + (thisTagIndex * 25);


                scrollProgress.value = dummyValue > 85 ? 85 : dummyValue;
              } else {
                scrollProgress.value = dummyValue
              }



            },
            // onEnter: () => {
            //   tags.forEach((tag) => {
            //     tag.current = false;
            //   })

            //   tag.current = true;
            // }
          });

        })
      })
    }
    onMounted(() => {
      setValues();


      window.addEventListener("resize", () => {
        // setValues();
        ScrollTrigger.refresh();
      });

    })

    return { scrollProgress, pureProgress, tags };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "./../styles/theme.scss";

body {
  .scroll-progress {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: auto;
    // background: #000a;
    // mix-blend-mode: difference;
    background-color: #f7f7f722;
    // a.scroll-progress__link {
    // }
    z-index: 999;
    // padding-block: 0.6em;
    box-sizing: border-box;
    // opacity: 0.7;

    --child-widths: calc(100vw - 20px);
    --tag-widths: calc((100vw - 20px) / 4);
    --tag-half-widths: calc(var(--tag-widths) / 2);
    --link-padding: 0.6em;

    &:hover {
      opacity: 1;
    }

    .scroll-progress__nav {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      width: 100%;
      width: var(--child-widths);
      margin-inline: auto;
      place-items: stretch;


      // justify-content: center;

      a.scroll-progress__link {
        color: inherit;
        text-align: center;
        width: var(--tag-widths);
        padding-block: var(--link-padding);

        &:hover,
        &:active {
          text-decoration: underline;
          background: #fffc;
          isolation: isolate;
          color: black;
        }
      }
    }


    .scroll-progress__bar,
    .scroll-progress__container {
      // isolation: isolate;
      mix-blend-mode: difference;
      background: none;
      width: var(--child-widths);

      margin-inline: auto;
      appearance: none;
      position: relative;

      .scroll-progress__progress {
        position: absolute;
        --progress-value: 0;
        --progress-radius: 0.6lh;
        --section-half-widths: var(--tag-half-widths, 115px);
        --start-value: calc(var(--section-half-widths) - 10px);
        --end-value: calc(var(--child-widths) - var(--start-value)); // transition: left cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.002s;
        // top: 0;
        left: clamp(var(--start-value), calc(((var(--progress-value)) * 1%) - var(--progress-radius)), var(--end-value));
        // left: 0;
        transition: left 0.2s ease-in-out;
        height: var(--progress-radius);
        aspect-ratio: 1;
        background: #000;
        border-radius: 50%;
        margin-block: 0.2em 0;
        box-sizing: border-box;
        animation: scale-in-out 4s linear 2s;
        // animation-iteration-count: 2;
      }


    }
  }

  @keyframes scale-in-out {
    0% {
      transform: scale(0.5);
      opacity: 0;
    }

    30%,
    60% {
      transform: scale(0.9);
    }

    // 45%,
    // 80% {
    //   transform: scale(0.4);
    // }

  }
}

@media only screen and (max-width: 600px) {
  body {
    .scroll-progress {
      top: 0;
      --link-padding: 0.9em;
      // bottom: 0;

    }
  }
}
</style>