import { ref } from "@vue/runtime-core";
import { widthFunction } from "@/composables/Mobile";


const { width, setMobile, getScreenCategory, isPhone } = widthFunction();



export function skillsAndJobs() {

    const skillsArray = ref([
        { name: "All", current: true },
        { name: "CSS", current: false },
        { name: "HTML", current: false },
        { name: "JAVASCRIPT", current: false },
        { name: "Vue.js", current: false },
        { name: "Python", current: false },
        { name: "Figma", current: false },
        { name: "Design", current: false },
    ]);

    const jobs = ref([
        {
            name: "Weather Dashboard",
            description: "Weather Dashboard with weather style theming",
            href: "https://weather.projectsbysam.dev/",
            github: "https://github.com/samade123/black-and-white-portfolio",
            image: require(`../assets/small/weather-iphone.webp`),
            imageLarge: require(`../assets/${getScreenCategory()}/weather-iphone.webp`),
            selected: false,
            bgImage: 'url(' + require('@/assets/featured/weather-mobile.webp') + ')',
            // bgColors: ['e2e0e3', 'e2e0e3'],
            bgColors: ['eff1f2', '87b4c6'],

            identifier: 'weather',
            tags: ["All", "Vue-js", "CSS", "HTML", "JAVASCRIPT"],
            // skills: [{"All"}, {"Vue.js"}, {"CSS"}"CSS", {}"HTML", {}"JAVASCRIPT"],
            otherFeatures: [
                "Color themeing (by weather)",
                "SASS",
                "Adaptive Design",
            ],
            expand: {
                'h3': { possible: false }, 'h4': { possible: true }
            },
            images:[{name: 'weather-1', label: 'Weather dashboard - Tablet view',}, {name: 'weather-2', label: 'Weather dashboard - mobile view',}],
        },
        {
            name: "Presentation Viewer - Phase 1",
            description:
                "A presentation viewer and database based off the Marp markup language; integrated with Notion database REST API",
            href: "https://cac-hymns.vercel.app/",
            github: "https://github.com/samade123/angular_cac_hymns_in_marp",
            image: require(`../assets/small/quick-design-task.webp`),
            imageLarge: require(`../assets/${getScreenCategory()}/quick-design-task.webp`),
            bgColors: ['e7dfdc', 'e7dfdc'],
            identifier: 'cac',
            selected: false,
            bgImage: 'url(' + require('@/assets/featured/cac.webp') + ')',
            tags: ["All", "CSS", "HTML", "Typescript", 'angular'],
            otherFeatures: ["Responsive Design"],
            expand: {
                'h3': { possible: false }, 'h4': { possible: true }
            }
        },
        {
            name: "Agency landing page - demo",
            description:
                "A basic demo of an animated landing page for a creative agency.",
            href: "https://agency-landing-demo.projectsbysam.dev/",
            github: "https://github.com/samade123/agency-landing-page",
            designRef:
                "https://dribbble.com/shots/15916355/attachments/7746820?mode=media",
            image: require(`../assets/small/agency-demo.webp`),
            imageLarge: require(`../assets/${getScreenCategory()}/agency-demo.webp`),
            bgColors: ['c6ba7e', 'd0a697'],
            identifier: 'agency',
            selected: false,
            bgImage: 'url(' + require('@/assets/featured/agency.webp') + ')',
            tags: ["All", "CSS", "HTML", "JAVASCRIPT"],
            otherFeatures: ["Responsive Design", "GSAP", "Animations"],
            expand: {
                'h3': { possible: false }, 'h4': { possible: true }
            }
        },
        {
            name: "Market Black - Concept Design",
            description: "Concept design of a e-commerce site",
            href: "https://www.figma.com/file/DwlOgHgPLpL2kyf8y08w4h/Market-Black-Original?node-id=0%3A1",
            github:
                "https://www.figma.com/file/DwlOgHgPLpL2kyf8y08w4h/Market-Black-Original?node-id=0%3A1",
            image: require(`../assets/small/market-black.webp`),
            imageLarge: require(`../assets/${getScreenCategory()}/market-black.webp`),
            bgImage: 'url(' + require('@/assets/featured/market-black.webp') + ')',
            bgColors: ['cfc9c6', 'cfc9c6'],
            identifier: 'figma',
            selected: false,
            tags: ["All", "Figma", "Design"],
            otherFeatures: [],
            expand: {
                'h3': { possible: false }, 'h4': { possible: true }
            }
        },
    ]);

    return {
        skillsArray, jobs
    };

}